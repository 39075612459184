<script>
import { machineTypeService } from "../../../helpers/backend/machine_type.service"
export default {
  data() {
    return {
      form: {
        id: null,
        name: "",
        total_row: 0,
        total_column: 0,
        total_depth: 0,
        is_online: true
      },
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      this.busy = true
      machineTypeService.createOrUpdate(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error = "Failed to Insert/Update Machine Type. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null
      this.form.name = ""
      this.form.total_row = 0
      this.form.total_column = 0
      this.form.total_depth = 0
      this.form.is_online = true
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(machineId) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.$refs.name.$el.focus()
        this.busy = true
        machineTypeService.getMachineTypeByID(machineId).then(
          (data) => {
            if (data.machine_type != null) {
              this.form.id = data.machine_type.id
              this.form.name = data.machine_type.name
              this.form.total_row = data.machine_type.total_row
              this.form.total_column = data.machine_type.total_column
              this.form.total_depth = data.machine_type.total_depth
              this.form.is_online = data.machine_type.is_online
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get machine to update. Error : Machine Type not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error = "Failed to get machine type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
  },
};
</script>


<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Machine Type Name:"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="form.name"
                  type="text"
                  placeholder="Machine Type Name"
                  required
                  ref="name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Total Row:"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.total_row"
                  placeholder="Enter Total Row"
                  type="number"
                  :required="form.id == null ? true : false"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Total Column:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.total_column"
                  placeholder="Enter Total Column"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-4"
                label="Total Depth:"
                label-for="input-4"
              >
                <b-form-input
                  id="input-4"
                  v-model="form.total_depth"
                  placeholder="Enter Total Depth"
                  type="number"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-5"
                label="VMCore Powered:"
                label-for="input-5"
              >
                <b-form-checkbox id="input-5" v-model="form.is_online">
                  VMCore Powered
                </b-form-checkbox>
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate">Create new</b-button>
    </b-form-group>
  </div>
</template>



